import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuctionProviderService {
    private baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/auctions';
    }

    get(filterParams) {

        Object.keys(filterParams).forEach((key)=>{
            if (filterParams[key] === 'all') delete(filterParams[key])
        })

        const searchParams: any = new URLSearchParams(filterParams);
        return this.http.get(`${this.baseUrl}?${(new URLSearchParams(searchParams)).toString()}`);

    }


    find(id: number) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

}
