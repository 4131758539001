import {Component, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Keyboard} from "@ionic-native/keyboard/ngx";

@Component({
    selector: 'app-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {


    @Input('label') label: string;
    @Input('placeholder') placeholder: string;
    @Input('model') model: string;
    @Input('type') type: string;

    constructor(private keyboard: Keyboard) {
        if (!this.type) this.type = 'text'
    }

    hideKeyboard() {

    }

    ngOnInit() {
    }

}
