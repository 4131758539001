import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ArticleProviderService {
    private baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/articles';
    }

    get(page, publication) {

        let filter = '';
        if (publication !== 'all') {
            filter += `&publication=${publication}`;
        }

        return this.http.get(`${this.baseUrl}?page=${page}${filter}`);
    }

    find(id: number) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

}
