import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-card-article',
  templateUrl: './card-article.component.html',
  styleUrls: ['./card-article.component.scss'],
})
export class CardArticleComponent implements OnInit {

  @Input() Article;
  @Input('favourite') favourite
  @Input() ShowImage = true

  @Output('onToggleFavourite') onToggleFavourite: EventEmitter<any> = new EventEmitter();
  @Output('onTap') onTap: EventEmitter<any> = new EventEmitter();

  targetType: string = 'article';

  constructor(
      private storage: Storage,
  ) { }

  ngOnInit() {}

  handleTap() {
    this.onTap.emit(this.Article);
  }

  handleToggleFavourite() {

    this.favourite = !this.favourite;

    if (this.favourite) {
      this.addToStorage();
    } else {
      this.removeFromStorage();
    }

    this.onToggleFavourite.emit(this.favourite);

  }

  async addToStorage() {
    let favourites = await this.storage.get('favourites');
    let array = favourites[this.targetType];
    array.push(this.Article.id);
    favourites[this.targetType] = array;;
    await this.storage.set('favourites', favourites);
  }

  async removeFromStorage() {
    let favourites = await this.storage.get('favourites');
    favourites[this.targetType] = favourites[this.targetType].filter(id => id !== this.Article.id);
    await this.storage.set('favourites', favourites);
  }

}
