import {HttpClient} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {isNumeric} from "rxjs/internal-compatibility";

@Injectable()
export class FiltersProviderService {

    constructor(public http: HttpClient) {
    }



    async dealers() {
        return new Promise((resolve, reject) => {
            try {
                this.http.get('/dealers').subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async auctioneers() {
        return new Promise((resolve, reject) => {
            try {
                this.http.get('/auctioneers').subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehicleType(advertType = 'advert') {
        return new Promise((resolve, reject) => {
            try {
                this.http.get(`/car-types?advert-type=${advertType}`).subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehicleMake(type_id?: number|undefined, advertType = 'advert') {

        if (!isNumeric(type_id)) type_id = undefined

        return new Promise((resolve, reject) => {
            try {
                this.http.get(`/makes?advert-type=${advertType}${ type_id ? '&type_id=' + type_id : '' }`).subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehicleModel(make_id: number, type_id?: number) {

        if (!make_id) return
        if (type_id && !isNumeric(type_id)) type_id = undefined

        return new Promise((resolve, reject) => {
            try {
                this.http.get(`/models?make_id=${make_id}${ type_id ? '&type_id=' + type_id : '' }`).subscribe(data => {
                    console.log(data)
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehicleAge() {
        return new Promise((resolve, reject) => {
            try {
                this.http.get('/filters/age').subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehicleMileage() {
        return new Promise((resolve, reject) => {
            try {
                this.http.get('/filters/mileage').subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    async vehiclePrice() {
        return new Promise((resolve, reject) => {
            try {
                this.http.get('/filters/price').subscribe(data => {
                    resolve(data)
                })
            } catch (e) {
                reject(e)
            }
        })
    }


}
