/// <reference path="WikitudePlugin.d.ts" />

import {Component, NgZone} from '@angular/core';

import {ModalController, NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {SplashPage} from "./pages/splash/splash.page";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {Vibration} from "@ionic-native/vibration/ngx";
import {Keyboard} from "@ionic-native/keyboard/ngx";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: []
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalController: ModalController,
    private screenOrientation: ScreenOrientation,
    private navController: NavController,
    private ngZone: NgZone,
    private vibration: Vibration,
    private keyboard: Keyboard
  ) {
    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleLightContent();

      if (this.platform.is('hybrid')){
        this.showSplash();

        this.keyboard.hideFormAccessoryBar(false)

        this.screenOrientation.lock('portrait');

        /** Enter your Wikitude (trial) License Key here. You can register and download your free license key here: http://www.wikitude.com/developer/licenses */
        WikitudePlugin._sdkKey = "NCJUDziCSqWU8vv11JWTMmPodD3QZywzjJ3wsztNK0W9aDXOhS7jw0Cmve2FdqS6wnwg6IH43dYBJCGRVYgjTdRSbseTLY31aXq/ufQ0IjK+h9dS7UKpvZBvOBLOf9mrhMKzSmHLUiYz0zykayic3Cwc+zPVY3VxbhQ0+2vv3ChTYWx0ZWRfX01XZacYsS9uO3qZmGiekxDEuO3oeA2AsdpfM9GNtyblvBfQ+MdXbfOxS6y7pUgUsOlYdlhJw/BsMuqIQ83aAm21pAMHncfGGZPjVXeeDol5MgI7blXgl1XcfPmZGwMaAS4frUJtwLbd9/D9KDdhxlXS9SLisLN7pcKNo/rj2Pdom80EdizIT8yh8w8CiZjvHhygxraz3fKNftQRdmyQgZFjGrwjdV1NwzGGaqb6pePeCQVy+hG5sphsn6unqL0gnO1mTO2Audx+dBrWitpTTmucLeZqXVUMD+l4EL1/CdkN0sqD+jtwAlctgxmIBeGf/wyMceQtKEKv/xsZcvNxNnUtCmLTiNNXRkUrRkjJN+ylqxRAbiMMcjLdl97tGg0ECE0MFNEUfexSJdtn4ghtW08xZcajTFv+Q4+bxWRiTCiOOOOD1C2c+i7tNoFzkl0pNlK+7YZhlRsY5Iq1ZFL1xYg/58Vb20omkHBym6sVou7Qek1MLH5FSCIqPe8s9ipvs1K60goGPkq2+X2yOZB/viIwUb/sklgNc3ozX0p4vqrLQD1HFbEeAGZAdc85AsLBwZFAWGxHTiKXRuxJWijkfZwVLKJwoTFqtO4BtbPzwFMhS5NkYbZNnXJmZ5/Ma4mT/DPsdQ8f/YNJUhqXnfms1cKxAYuMvEKZvA==";

        /** Check if your device supports AR */
        WikitudePlugin.isDeviceSupported(
            function(success) {
              console.log("Your platform supports AR/Wikitude. Have fun developing!!");
            },
            function(fail) {
              console.log("Your platform failed to run AR/Wikitude: "+fail);
            },
            [WikitudePlugin.FeatureImageTracking] // or WikitudePlugin.Feature2DTracking
        );



        /**
         * Define the generic ok callback
         */
        WikitudePlugin.onWikitudeOK = function() {
          console.log("Things went ok.");
        }

        /**
         * Define the generic failure callback
         */
        WikitudePlugin.onWikitudeError = function() {
          console.log("Something went wrong");
        }

        // Just as an example: set the location within the Wikitude SDK, if you need this (You can get the geo coordinates by using ionic native
        // GeoLocation plugin: http://ionicframework.com/docs/v2/native/geolocation/
        //WikitudePlugin.setLocation(47, 13, 450, 1);

        /* for Android only
        WikitudePlugin.setBackButtonCallback(
            () => {
                console.log("Back button has been pressed...");
            }
        );
        */

      }

    });
  }
    
  async showSplash() {

    const modal = await this.modalController.create({
      component: SplashPage,
      cssClass: 'splash',
      animated: false,
    });

    return await modal.present().then(()=>{
      setTimeout(()=>{
        this.splashScreen.hide();
      },50);
    });

  }
}
