import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges, SimpleChange} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {

  @Input('allValuesLabel') allValuesLabel: string = 'All';
  @Input('hasAllValuesOption') hasAllValuesOption: boolean = true;
  @Input('value') value: any;
  @Input('name') name: any;
  @Input('placeholder') placeholder: string;
  @Input('options') options: Array<any>;
  @Output('updateSelection') updateSelection: EventEmitter<any> = new EventEmitter();

  model: any;
  choices: any;

  constructor() {

  }

  ngOnInit() {

  }

  setOptionValue() {

    this.choices.map(option => {

      if (!option.hasOwnProperty('value')) return

      if (option.value ? option.value : option.name === this.value) {
        this.model = this.value;
      }
    });
  }

  update(event) {
    this.model = event.detail.value;
    this.updateSelection.emit(event);
  }

  ngOnChanges(changes: SimpleChange) {

    if (this.hasAllValuesOption) {
      this.choices = [{
        name: this.allValuesLabel,
        value: 'all'
      }].concat(this.options);
    } else {
      this.choices = this.options;
    }

    this.setOptionValue();

  }

}
