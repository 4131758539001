import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy, IonNav} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Vibration} from '@ionic-native/vibration/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {MarkdownModule} from 'ngx-markdown';
import {ApiInterceptorService} from "./services/interceptors/api-interceptor.service";
import {AdvertProviderService} from "./services/providers/advert-provider/advert-provider.service";
import {AuctionProviderService} from "./services/providers/auction-provider/auction-provider.service";
import {MakeProviderService} from "./services/providers/make-provider/make-provider.service";
import {TypeProviderService} from "./services/providers/type-provider/type-provider.service";
import {VisitProviderService} from "./services/providers/visit-provider/visit-provider.service";
import {PublicationProviderService} from "./services/providers/publication-provider/publication-provider.service";
import {BannerAdvertProviderService} from "./services/providers/banner-advert-provider/banner-advert-provider.service";
import {ArticleProviderService} from "./services/providers/article-provider/article-provider.service";
import {ContentPageProviderService} from "./services/providers/content-page-provider/content-page-provider.service";
import {DealerProviderService} from "./services/providers/dealer-provider/dealer-provider.service";
import {AuctioneerProviderService} from "./services/providers/auctioneer-provider/auctioneer-provider.service";
import {EnquiryProviderService} from "./services/providers/enquiry-provider/enquiry-provider.service";
import {MultipleTargetProviderService} from "./services/providers/multiple-target-provider/multiple-target-provider.service"
import {ImageRecognitionProviderService} from "./services/providers/image-recognition-provider/image-recognition-provider.service";
import {TabsProviderService} from "./services/providers/tabs-provider/tabs-provider.service";
import {SocialSharing} from "@ionic-native/social-sharing/ngx";
import {FormBuilder} from "@angular/forms";
import {YoutubeVideoPlayer} from "@ionic-native/youtube-video-player/ngx";
// import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {Diagnostic} from '@ionic-native/diagnostic/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

import {defineCustomElements} from '@teamhive/lottie-player/loader';
import {ScrollVanishDirective} from "./directives/scroll-vanish.directive";
import {ComponentsModule} from "./components/component.module";
import {FinanceEnquiryProviderService} from "./services/providers/finance-enquiry-provider/finance-enquiry-provider.service";
import {Keyboard} from "@ionic-native/keyboard/ngx";
import {FiltersProviderService} from "./services/providers/filters-provider/filters-provider.service";


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        MarkdownModule.forRoot(),
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot({
            name: 'target__storage',
            driverOrder: ['sqlite', 'indexeddb', 'websql']
        }),
        ComponentsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptorService,
            multi: true
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        StatusBar,
        SplashScreen,
        Vibration,
        SocialSharing,
        TabsProviderService,
        ScreenOrientation,
        AdvertProviderService,
        AuctionProviderService,
        MakeProviderService,
        TypeProviderService,
        VisitProviderService,
        PublicationProviderService,
        BannerAdvertProviderService,
        ArticleProviderService,
        ContentPageProviderService,
        DealerProviderService,
        AuctioneerProviderService,
        EnquiryProviderService,
        ImageRecognitionProviderService,
        MultipleTargetProviderService,
        FinanceEnquiryProviderService,
        FormBuilder,
        YoutubeVideoPlayer,
        // PhotoViewer,
        IonNav,
        Diagnostic,
        InAppBrowser,
        Keyboard,
        FiltersProviderService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}
