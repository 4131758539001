import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MakeProviderService {
    private baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/makes';
    }

    get(type, dealer = null, advertType = 'advert') {

        let params = '';
        if(type && type !== 'all') {
            params += `&type=${type}`;
        }

        if(dealer && dealer !== 'all') {
            params += `&dealer=${dealer}`;
        }

        params+= `&advert-type=${advertType}`;

        return this.http.get(`${this.baseUrl}?${params}`);
    }
}
