import {Component, Input, Output, OnInit, EventEmitter, SimpleChange} from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {Storage} from "@ionic/storage";
import {VisitProviderService} from "../../services/providers/visit-provider/visit-provider.service";
import {formatNumber} from "@angular/common";

@Component({
  selector: 'app-card-advert',
  templateUrl: './card-advert.component.html',
  styleUrls: ['./card-advert.component.scss'],
})
export class CardAdvertComponent implements OnInit {

  @Input('show') show;
  @Input() Advert;
  @Input('favourite') favourite;
  @Input() ShowImage = true

  @Output('onToggleFavourite') onToggleFavourite: EventEmitter<any> = new EventEmitter();
  @Output('onTap') onTap: EventEmitter<any> = new EventEmitter();

  targetType: string = 'advert';

  constructor(
      private storage: Storage,
      private visitProvider: VisitProviderService,
  ) { }

  ngOnInit() {
  }

  handleTap() {
    if(!this.ShowImage) {
      this.logVisit('Image Gallery');
    }

    this.onTap.emit(this.Advert);
  }

  resolveAdvertName(advert) {
    let name = ''
    if (advert.make) name += advert.make
    if (advert.model) name += ' ' + advert.model
    if (advert.year) name += ' / ' + advert.year
    if (advert.mileage) name += ' / ' + advert.mileage
    return name
  }

  handleToggleFavourite() {

    this.favourite = !this.favourite;

    if (this.favourite) {
      this.addToStorage();
    } else {
      this.removeFromStorage();
    }

    this.onToggleFavourite.emit(this.favourite);

  }

  async logVisit(identifier) {
    this.visitProvider.create(this.Advert, identifier).subscribe();
  }

  async addToStorage() {
    let favourites = await this.storage.get('favourites');
    let array = favourites[this.targetType];
    array.push(this.Advert.id);
    favourites[this.targetType] = array;;
    await this.storage.set('favourites', favourites);
  }

  async removeFromStorage() {
    let favourites = await this.storage.get('favourites');
    favourites[this.targetType] = favourites[this.targetType].filter(id => id !== this.Advert.id);
    await this.storage.set('favourites', favourites);
  }

}
