import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class VisitProviderService {
  private baseUrl: string;

  constructor(public http: HttpClient) {
    this.baseUrl = '/visits';
  }

  create(target, identifier) {
    let params = {
      'id': target.id,
      'type': target.type,
      'identifier': identifier
    };

    return this.http.post(this.baseUrl, params);
  }
}
