import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
})
export class AdvertisementComponent implements OnInit {

  constructor() { }

  @Input() advertisements;

  advertisement: any;

  ngOnInit() {

    // Set random advert
    this.advertisement = this.advertisements[Math.floor(Math.random() * this.advertisements.length)];

  }

}
