import {Component, Input, Output, OnInit, EventEmitter, SimpleChange} from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {Storage} from "@ionic/storage";
import {VisitProviderService} from "../../services/providers/visit-provider/visit-provider.service";

@Component({
    selector: 'app-card-auction',
    templateUrl: './card-auction.component.html',
    styleUrls: ['./card-auction.component.scss'],
})
export class CardAuctionComponent implements OnInit {

    @Input('show') show;
    @Input() Auction;
    @Input('favourite') favourite;
    @Input() ShowImage = true

    @Output('onToggleFavourite') onToggleFavourite: EventEmitter<any> = new EventEmitter();
    @Output('onTap') onTap: EventEmitter<any> = new EventEmitter();

    targetType: string = 'auction';

    constructor(
        private storage: Storage,
        private visitProvider: VisitProviderService,
    ) {
    }

    ngOnInit() {
    }

    handleTap() {
        if (!this.ShowImage) {
            this.logVisit('Image Gallery');
        }
        this.onTap.emit(this.Auction);
    }

    resolveAdvertName(advert) {
        let name = ''
        if (advert.make) name += advert.make
        if (advert.model) name += ' ' + advert.model
        if (advert.year) name += ' / ' + advert.year
        if (advert.mileage) name += ' / ' + advert.mileage
        return name
    }

    handleToggleFavourite() {

        this.favourite = !this.favourite;

        if (this.favourite) {
            this.addToStorage();
        } else {
            this.removeFromStorage();
        }

        this.onToggleFavourite.emit(this.favourite);

    }

    async logVisit(identifier) {
        this.visitProvider.create(this.Auction, identifier).subscribe();
    }

    async addToStorage() {
        let favourites = await this.storage.get('favourites');
        let array = favourites[this.targetType];
        array.push(this.Auction.id);
        favourites[this.targetType] = array;
        await this.storage.set('favourites', favourites);
    }

    async removeFromStorage() {
        let favourites = await this.storage.get('favourites');
        favourites[this.targetType] = favourites[this.targetType].filter(id => id !== this.Auction.id);
        await this.storage.set('favourites', favourites);
    }

}
