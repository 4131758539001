import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'advert-placeholder',
  templateUrl: './advert-placeholder.html',
  styleUrls: ['./advert-placeholder.scss'],
})
export class AdvertPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
