import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class BannerAdvertProviderService {
    private baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/banner-adverts';
    }

    get() {
        return this.http.get(`${this.baseUrl}`);
    }
}
