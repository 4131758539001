import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage";

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MultipleTargetProviderService {
    private baseUrl: string;

    constructor(
        public http: HttpClient,
        public storage: Storage
    ) {
        this.baseUrl = '/multiple-targets';
    }

    findMany(targets: any) {
        return new Promise((resolve,reject)=>{
            const adverts = targets.advert ? targets.advert.join(',') : '';
            const articles = targets.article ? targets.article.join(','): '';
            const auctions = targets.auction ? targets.auction.join(','): '';
            this.http.get(`${this.baseUrl}?adverts=${adverts}&articles=${articles}&auctions=${auctions}`).subscribe(response=>{
                return resolve(this.setFavourites(response));
            });
        });
    }

    async getFavourites() {
        return await this.storage.get('favourites');
    }

    async setFavourites(data: any) {
        const favouriteAdvertArticleCollection = await this.getFavourites();
        const favouriteAdvertsCollection = favouriteAdvertArticleCollection.advert;
        const favouriteArticleCollection = favouriteAdvertArticleCollection.article;
        const favouriteAuctionCollection = favouriteAdvertArticleCollection.auction;
        let favouriteIds = favouriteAdvertsCollection.concat(favouriteArticleCollection).concat(favouriteAuctionCollection);
        favouriteIds = [...new Set(favouriteIds)]; // make unique

        data['adverts'].map(item=>{
            item['favourite'] = favouriteIds.includes(item.id);
            return item;
        });

        data['articles'].map(item=>{
            item['favourite'] = favouriteIds.includes(item.id);
            return item;
        });

        data['auctions'].map(item=>{
            item['favourite'] = favouriteIds.includes(item.id);
            return item;
        });

        return data;
    }

}
