import {Component, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Keyboard} from "@ionic-native/keyboard/ngx";

@Component({
    selector: 'app-email-field',
    templateUrl: './email-field.component.html',
    styleUrls: ['./email-field.component.scss'],
})
export class EmailFieldComponent implements OnInit {


    @Input('label') label: string;
    @Input('placeholder') placeholder: string;

    constructor(private keyboard: Keyboard) {
    }

    hideKeyboard() {

    }


    ngOnInit() {
    }

}
