// https://www.joshmorony.com/creating-a-custom-scroll-vanish-directive-with-ionic-web-components/

import {Directive, Input, ElementRef, Renderer2, OnInit, Output, EventEmitter} from "@angular/core";
import { DomController } from "@ionic/angular";

@Directive({
  selector: "[myScrollVanish]"
})
export class ScrollVanishDirective implements OnInit {

  @Input("myScrollVanish") scrollArea;
  @Output('visibilityChanged') visibilityChanged: EventEmitter<any> = new EventEmitter();

  public hidden: boolean = false;
  private triggerDistance: number = 0;

  constructor(
      private element: ElementRef,
      private renderer: Renderer2,
      private domCtrl: DomController
  ) {}

  ngOnInit() {

    this.initStyles();

    this.scrollArea.ionScroll.subscribe(scrollEvent => {
      let delta = scrollEvent.detail.deltaY;

      if (scrollEvent.detail.scrollTop <= 0) return;

      if (scrollEvent.detail.currentY === 0 && this.hidden) {
        // this.show();
      } else if (!this.hidden && delta > this.triggerDistance) {
        this.hide();
      } else if (this.hidden && delta < -this.triggerDistance) {
        this.show();
      }
    });

    this.hide();

  }

  initStyles() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, "transition", "0.2s linear");
    });
  }

  hide() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, "min-height", "0px");
      this.renderer.setStyle(this.element.nativeElement, "opacity", "0");
      this.renderer.setStyle(this.element.nativeElement, "padding", "0");
      this.renderer.setStyle(this.element.nativeElement, "visibility", "hidden");
    });

    this.hidden = true;

    this.visibilityChanged.emit(this.hidden);

  }

  show() {
    this.domCtrl.write(() => {
      this.renderer.removeStyle(this.element.nativeElement, "opacity");
      this.renderer.removeStyle(this.element.nativeElement, "min-height");
      this.renderer.removeStyle(this.element.nativeElement, "padding");
      this.renderer.setStyle(this.element.nativeElement, "visibility", "visible");
    });

    this.hidden = false;

    this.visibilityChanged.emit(this.hidden);

  }
}
