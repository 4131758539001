import {Component, Input, OnInit} from '@angular/core';
import {YoutubeVideoPlayer} from "@ionic-native/youtube-video-player/ngx";
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-slider-video',
  templateUrl: './slider-video.component.html',
  styleUrls: ['./slider-video.component.scss'],
})

export class SliderVideoComponent implements OnInit {


  @Input() item;

  constructor(
      private youtube: YoutubeVideoPlayer,
      private platform: Platform
  ) {
   }

  ngOnInit() {

  }

  play() {
    if (this.platform.is('hybrid')) {
      this.youtube.openVideo(this.item.url);
    } else {
      window.open(`https://www.youtube.com/watch?v=${this.item.url}`, '_blank');
    }
  }

}
