import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {EmptyStateComponent} from "./empty-state/empty-state.component";
import {AdvertisementSliderComponent} from "./advertisement-slider/advertisement-slider.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {AdvertPlaceholderComponent} from "./advert-placeholder/advert-placeholder.component";
import {ArticlePlaceholderComponent} from "./article-placeholder/article-placeholder.component";
import {CardAdvertComponent} from "./card-advert/card-advert.component";
import {CardAuctionComponent} from "./card-auction/card-auction.component";
import {CardArticleComponent} from "./card-article/card-article.component";
import {MediaSliderComponent} from "./media-slider/media-slider.component";
import {SliderVideoComponent} from "./slider-video/slider-video.component";
import {FormsModule} from "@angular/forms";
import {ScrollVanishDirective} from "../directives/scroll-vanish.directive";
import {AdvertisementComponent} from "./advertisement/advertisement.component";
import {InputFieldComponent} from "./input-field/input-field.component";
import {EmailFieldComponent} from "./email-field/email-field.component";
import {TextareaFieldComponent} from "./textarea-field/textarea-field.component";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
    declarations: [
        EmptyStateComponent,
        AdvertisementSliderComponent,
        AdvertisementComponent,
        DropdownComponent,
        AdvertPlaceholderComponent,
        ArticlePlaceholderComponent,
        CardAdvertComponent,
        CardAuctionComponent,
        CardArticleComponent,
        MediaSliderComponent,
        SliderVideoComponent,
        ScrollVanishDirective,
        InputFieldComponent,
        EmailFieldComponent,
        TextareaFieldComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        FormsModule,
    ],
    exports: [
        EmptyStateComponent,
        AdvertisementSliderComponent,
        AdvertisementComponent,
        DropdownComponent,
        AdvertPlaceholderComponent,
        ArticlePlaceholderComponent,
        CardAdvertComponent,
        CardAuctionComponent,
        CardArticleComponent,
        MediaSliderComponent,
        SliderVideoComponent,
        ScrollVanishDirective,
        InputFieldComponent,
        EmailFieldComponent,
        TextareaFieldComponent,
    ],
})
export class ComponentsModule {
}
