import { Injectable } from '@angular/core';

/*
  Generated class for the TabsServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TabsProviderService {

  tabBarElement: HTMLElement;

  constructor() {
      this.tabBarElement = document.querySelector('#tabs ion-tab-bar');
  }

  show(){
    this.tabBarElement.style.display = 'flex';
  }

  hide(){
    this.tabBarElement.style.display = 'none';
  }


}
