import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-advertisement-slider',
  templateUrl: './advertisement-slider.component.html',
  styleUrls: ['./advertisement-slider.component.scss']
})
export class AdvertisementSliderComponent implements OnInit {

  slideOpts: object = {
    initialSlide: 0,
    speed: 400,
    autoplay: {
      delay: 4000
    }
  };

  @Input() bannerAdverts;

  constructor() { }

  ngOnInit() {}

}
