import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {

  @Input('icon') icon: string;
  @Input('title') title: string;
  @Input('text') text: string;

  constructor() { }

  ngOnInit() {}

}
