import { Component, OnInit, Input } from '@angular/core';
// import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import * as PhotoSwipe from "photoswipe/dist/photoswipe";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss'],
})
export class MediaSliderComponent implements OnInit {

  @Input() Media;
  @Input() ExpandOnTap = false;

  pswp: any;
  slideOpts: any = {};

  constructor(
    // private photoViewer: PhotoViewer,
    private screenOrientation: ScreenOrientation,
  ) {
   }

  ngOnInit() {
    this.Media = this.Media.map(item => {
      if (item.type === 'Video') {
        if (item.url.includes('watch')) {
          const index = item.url.indexOf('watch') + 8;
          let id = item.url.slice(index);
          item.url = id;  
        }
      }
      return item;
    });

    this.screenOrientation.onChange().subscribe(()=>{
      this.pswp.updateSize(true);
    });
  }

  // showImage(image) {
  //   console.log(image);
  //   if(this.ExpandOnTap) {
  //     this.photoViewer.show(image.url);
  //   }
  // }

  async showImage(index) {
    if(this.ExpandOnTap) {

      this.screenOrientation.unlock();

      var pswpElement = document.querySelectorAll('.pswp')[0];

      // build items array
      var items = [];

      this.Media.forEach((item:any) => {
        items.push({
          src: item.url,
          w: 0,
          h: 0
        })
      });

      // define options (if needed)
      var options = {
        // optionName: 'option value'
        // for example:
        index: index, // start at first slide
        captionEl: false,
        fullscreenEl: false,
        zoomEl: false,
        shareEl: false,
        arrowEl: false,
        closeOnVerticalDrag: true
      };

      this.pswp = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);

      this.pswp.listen('gettingData', (index, item) => {
        if (item.w < 1 || item.h < 1) {
          var img = new Image();
          img.onload =  (event: any) => {
            item.w = event.srcElement.naturalWidth;
            item.h = event.srcElement.naturalHeight;
            this.pswp.updateSize(true);
          };
          img.src = item.src;
        }
      });

      this.pswp.listen('close', () => {
        this.screenOrientation.lock('portrait');
        this.pswp.close();
      });

      this.pswp.init();

    }
  }
}
