import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TypeProviderService {
    private baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/car-types';
    }

    get(make, dealer = null) {

        let params = '';

        if(make !== 'all') {
            params += `&make=${make}`;
        }

        if(dealer !== null) {
            params += `&dealer=${dealer}`;
        }

        return this.http.get(`${this.baseUrl}?${params}`);
    }
}
