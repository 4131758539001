import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the JsonProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ImageRecognitionProviderService {
    readonly baseUrl: string;

    constructor(public http: HttpClient) {
        this.baseUrl = '/image-recognition';
    }

    find(id: string): any {
        const url = `${this.baseUrl}/${id}`;
        return this.http.get(url);
    }

}
