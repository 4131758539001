import { Component, OnInit } from '@angular/core';
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-splash',
  templateUrl: './splash.page.html',
  styleUrls: ['./splash.page.scss'],
})
export class SplashPage implements OnInit {

  player: any;

  constructor(
      private splashScreen: SplashScreen,
      private modalController: ModalController
  ) { }

  ngOnInit() {
    setTimeout(()=>{
      this.hide();
    },100);

  }

  hide() {
    setTimeout(() => {
      this.modalController.dismiss();
    }, 3500);
  }

}
