import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ApiInterceptorService implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const url = environment.api.base_url;

    return from(this.getToken()).pipe(mergeMap((token) => {
      const changedReq = request.clone({
        url: url + request.url,
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
          'access-control-expose-headers': 'Location'
        }
      });

      return next.handle(changedReq);
    }));
  }

  async getToken() {
    return environment.api.token;
  }
}
